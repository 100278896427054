import React, { memo } from 'react';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';

import ApplicationLayout from 'react/business/layout/ApplicationLayout';
import ProductFormIntroductionStep from 'react/pages/product/form/step-0-intro/ProductFormIntroductionStep';

import classNames from './product-create.module.scss';

const enhancer = compose(
  memo,
);

const ProductCreateFormTuto = () => (
  <ApplicationLayout mainClassName={classNames.container}>
    <Helmet>
      <title>Product form</title>
    </Helmet>
    <ProductFormIntroductionStep />
  </ApplicationLayout>
);

export default enhancer(ProductCreateFormTuto);
