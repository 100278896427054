import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Icon } from 'antd';
import { formatRoute } from 'react-router-named-routes';
import cn from 'classnames';

import ROUTES from 'react/routes';

import Link from 'react/generic/link/Link';
import Button from 'react/generic/button/Button';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import picture from './picture.png';
import classNames from './product-form-introduction-step.module.scss';
import messages from './product-form-introduction-step.messages';

const ProductFormIntroductionStep = () => (
  <div className={classNames.container}>
    <div className={classNames.title}>
      <FormattedMessage {...messages.TITLE} />
    </div>
    <div className={classNames.body}>
      <div className={classNames.imgContainer}>
        <img className={classNames.img} src={picture} alt="" />
      </div>
      <div className={classNames.howToContainer}>
        <div className={classNames.howToTitle}>
          <FormattedMessage {...messages.HOW_TO_TITLE} />
        </div>
        <div className={classNames.howTo}>
          <FormattedMessage {...messages.HOW_TO_1} />
          <div className={classNames.text}>
            <FormattedMessage {...messages.HOW_TO_2} />
          </div>
          <FormattedMessage {...messages.HOW_TO_3} />
        </div>
        <div className={classNames.howToBasics}>
          <div className={classNames.howToBasic}>
            <Icon className={classNames.icon} type="clock-circle" />
            <div className={classNames.text}>
              <FormattedMessage {...messages.COMPLETION_TIME} />
            </div>
          </div>
          <div className={classNames.howToBasic}>
            <div className={classNames.hints}>
              <div className={classNames.row}>
                <Asset className={classNames.icon} name={ICON_MAP.idea} />
                <div className={classNames.text}>
                  <FormattedMessage {...messages.HINTS} />
                </div>
              </div>
              <div className={classNames.hint}>
                <FormattedHTMLMessage {...messages.HINTS_LOGO} />
              </div>
              <div className={classNames.hint}>
                <FormattedHTMLMessage {...messages.HINTS_PHOTO} />
              </div>
              <div className={classNames.hint}>
                <FormattedHTMLMessage {...messages.HINTS_DESCRIPTION} />
              </div>
            </div>
          </div>
          <div className={classNames.howToBasic}>
            <Asset className={classNames.icon} name={ICON_MAP.save} />
            <div className={cn(classNames.text, classNames.margin)}>
              <FormattedMessage {...messages.SAVING} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={classNames.ctaContainer}>
      <Link
        to={formatRoute(
          ROUTES.PRODUCT.CREATE_FORM.CHOOSE,
        )}
      >
        <Button>
          <FormattedMessage {...messages.NEXT} />
        </Button>
      </Link>
    </div>
  </div>
);

export default ProductFormIntroductionStep;
